export default [
	{
		name: "YandexMaps",
		layerType: "base",
		visible: true,
		url: "https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=22.05.23-0-b220520184800&x={x}&y={y}&z={z}&scale=2&lang=ru_RU",
		attribution:
			'&copy; <a target="_blank" href="https://yandex.ru/legal/right_holders/">Yandex maps</a> contributors'
	},
	{
		name: "OpenStreetMap",
		layerType: "base",
		visible: false,
		attribution: '&copy; <a target="_blank" href="https://osm.org/copyright">OpenStreetMap</a> contributors',
		url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
	},
	{
		name: "Google Sat",
		layerType: "base",
		visible: false,
		url: "https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
		subdomains: ["mt0", "mt1", "mt2", "mt3"],
		attribution: "Google maps"
	},
	{
		name: "Google Streets",
		layerType: "base",
		visible: false,
		url: "https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
		subdomains: ["mt0", "mt1", "mt2", "mt3"],
		attribution: "Google maps"
	},
	{
		name: "OpenTopoMap",
		layerType: "base",
		visible: false,
		url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
		attribution:
			'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="https://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
	}
]
